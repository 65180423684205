:root {
    --app-height: 100%;
}

body {
    background-color: white;
}

.enlarged-btn {
    svg {
        transform: scale(2);
    }
}

.original-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px black;
    }
}

.orange-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px rgb(19, 57, 59);
    }
}

.blue-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px #17255a;
    }
}

.green-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px #4a5240;
    }
}

.red-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px #500000;
    }
}

.pink-theme-btn {
    &:hover {
        box-shadow: 0 0 25px 10px #8f4e5d;
    }
}

.theme-icon {
    transition: transform 250ms, box-shadow 250ms;
    border: white;
    border-width: 1px;
    border-style: solid;

    cursor: pointer;

    &:hover {
        border-width: 0px;
        // box-shadow: 0 0 25px 10px black;
        transform: scale(1.1);
    }
}

.theme-icon-selected {
    transition: 250ms;
    border: #ff2800;
    border-width: 2px;
    border-style: solid;

    // cursor: pointer;

    // &:hover {
    //     border-width: 1px;
    //     transform: scale(1.05);
    // }
}

.themed-buttons {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #ff2800;

        &:active {
            background-color: #d12100;
        }

        &:hover {
            background-color: #9c1800;
        }
    }
}

.tab-no-padding {
    .bx--tabs--scrollable.bx--tabs--scrollable--container
        .bx--tabs--scrollable__nav-link {
        padding: 0px !important;
        width: 45px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.main-content {
    width: 100%;
    //padding: 3rem;
    padding: 1rem;
    background-color: transparent;
}

@media (max-width: 767px) {
    .main-content {
        padding: 0px !important;
    }
}

.hidden-tab-content {
    .bx--tab-content {
        display: none;
    }
}

.clear-tab-content {
    .bx--tab-content {
        padding: 0px;
    }
}

.clear-tab-content-with-padding {
    .bx--tab-content {
        padding: 1rem;
    }
}

.full-perc-height-tab-content {
    .bx--tab-content {
        height: 100%;
    }
}

.item-form {
    legend {
        margin-bottom: 2px;
    }
}

.white-search {
    svg {
        fill: white !important;
    }

    .bx--search-close::before {
        background-color: #8d8d8d;
        width: 1px;
        display: none;
    }

    .bx--search--expandable .bx--search-magnifier:hover {
        background-color: #212121;
    }

    .bx--search-close:hover,
    .bx--search-button:hover {
        background-color: #212121;
    }
}

.menu-tile {
    .bx--tile {
        width: 200px;
        height: 200px;

        background-color: #141414;

        color: white;

        &:hover {
            background-color: #1c1c1c;
        }
    }
}

.menu-btn-grid {
    flex-direction: column;
    justify-content: center;
    height: 96% !important;
    width: 90% !important;
    position: relative;

    h4 {
        margin-left: unset !important;
        flex: unset !important;
        margin-top: 1rem;
        text-align: center;
    }
}

.menu-btn {
    min-height: auto !important;
    width: 200px;
    transform: scale(1);

    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;

    &:hover {
        transform: scale(1.03);
    }

    &.black {
        color: white;

        background-color: rgb(60, 60, 60);
        &:hover {
            background-color: rgb(88, 88, 88);
            transform: scale(1.03);
        }
    }

    &.white {
        color: black;

        background-color: white;
        &:hover {
            background-color: #d3d3d3;
            transform: scale(1.03);
        }
    }

    &.blue {
        color: white;

        background-color: #374a67;
        &:hover {
            background-color: #3d5a86;
            transform: scale(1.03);
        }
    }

    &.green {
        color: white;

        background-color: #17255a;
        &:hover {
            background-color: #162a72;
            transform: scale(1.03);
        }
    }

    &.pink {
        color: white;

        background-color: #6c464f;
        &:hover {
            background-color: #8f4e5d;
            transform: scale(1.03);
        }
    }

    &.yellow {
        color: white;

        background-color: #ac8325;
        &:hover {
            background-color: #ce9a21;
            transform: scale(1.03);
        }
    }

    &.dark-pink {
        color: white;

        background-color: #417b5a;
        &:hover {
            background-color: #429265;
            transform: scale(1.03);
        }
    }

    &.light-blue {
        color: white;

        background-color: #4a5240;
        &:hover {
            background-color: #526140;
            transform: scale(1.03);
        }
    }

    &.red {
        color: white;

        background-color: #500000;
        &:hover {
            background-color: #990000;
            transform: scale(1.03);
        }
    }

    &.orange {
        color: white;

        background-color: #b64900;
        &:hover {
            background-color: #e75d00;
            transform: scale(1.03);
        }
    }

    &.purple {
        color: white;

        background-color: #780066;
        &:hover {
            background-color: #91007c;
            transform: scale(1.03);
        }
    }

    &.light-blue-2 {
        color: white;

        background-color: #007078;
        &:hover {
            background-color: #008891;
            transform: scale(1.03);
        }
    }

    &.hr-color {
        color: white;

        background-color: #009608;
        &:hover {
            background-color: #00a408;
            transform: scale(1.03);
        }
    }

    &.purple2 {
        color: white;

        background-color: #410041;
        &:hover {
            background-color: #660066;
            transform: scale(1.03);
        }
    }

    &.green2 {
        color: white;

        background-color: #003624;
        &:hover {
            background-color: #005035;
            transform: scale(1.03);
        }
    }
}

// .menu-tile-2 {
//     .bx--tile {
//         min-height: auto !important;

//         width: 200px;
//         //height: 200px;

//         background-color: #141414;

//         color: white;
//         transform: scale(1);

//         &:hover {
//             background-color: #1c1c1c;

//             transform: scale(1.03);
//             //padding-left: 3rem;
//         }
//     }
// }

.rate-star {
    transition: 150ms;

    &:hover {
        transform: scale(1.1);
    }
}

.bx--btn--primary {
    //background-color: #ff2616;
}

.full-width-date-picker {
    .bx--date-picker-input__wrapper {
        flex: 1;
    }

    .bx--date-picker--range .bx--date-picker-container,
    .bx--date-picker--range .bx--date-picker__input {
        flex: 1;
    }
}

.full-width-accordion {
    .bx--accordion__content {
        padding-right: 1rem;
    }
}

.full-width-accordion-small {
    .bx--accordion__content {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }
}

.horizontal-tile-radio {
    width: 100%;

    .bx--tile {
        flex: 1 !important;
    }

    div:first-of-type {
        display: flex;
        gap: 5px;
    }
}

.horizontal-tile-radio-without-width {
    .bx--tile {
        flex: 1 !important;
    }

    div:first-of-type {
        display: flex;
        gap: 5px;
    }
}

.dark-text-input {
    .bx--label {
        color: rgba(255, 255, 255, 0.65);
        margin-bottom: 0px;
    }
}

@keyframes scale-bounce-anim {
    0% {
        transform: scale(1);
        background-color: #00000000;
    }
    25% {
        transform: scale(1.01);
        background-color: #00000025;
    }
    75% {
        transform: scale(1.01);
        background-color: #00000025;
    }
    100% {
        transform: scale(1);
        background-color: #00000000;
    }
}

@keyframes quantity-increase-anim {
    0% {
        transform: scale(1);
        color: rgb(82, 82, 82);
        font-weight: 400;
    }
    50% {
        transform: scale(1.2);
        color: green;
        font-weight: 800;
    }
    100% {
        transform: scale(1);
        color: rgb(82, 82, 82);
        font-weight: 400;
    }
}

@keyframes quantity-decrease-anim {
    0% {
        transform: scale(1);
        color: rgb(82, 82, 82);
        font-weight: 400;
    }
    50% {
        transform: scale(1.2);
        color: red;
        font-weight: 800;
    }
    100% {
        transform: scale(1);
        color: rgb(82, 82, 82);
        font-weight: 400;
    }
}

@keyframes monitoring-preview-value-anim {
    0% {
        transform: scale(1);
        color: inherit;
    }
    50% {
        transform: scale(1.2);
        color: #0f62fe !important;
    }
    100% {
        transform: scale(1);
        color: inherit;
    }
}

.full-size-dialog {
    .bx--modal-container {
        width: 98vw;
        height: 95vh;

        max-width: none;
        max-height: none;
    }
}

.full-width-modal {
    .bx--modal-container {
        width: unset !important;
        height: unset !important;
        max-width: unset !important;
        max-height: unset !important;
    }
}

@keyframes monitor-checkout-dialog-backdrop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes monitor-checkout-dialog {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes custom-combo-box-drop-down {
    0% {
        height: 0px;
    }
    100% {
        height: 250px;
    }
}

.bx--header-panel--expanded {
    width: 400px;
}

.small-date-picker {
    .bx--date-picker-container {
        width: 100%;
    }

    .bx--date-picker-input__wrapper {
        width: 100%;
    }

    .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
        //width: auto;
        width: 100%;
    }

    .bx--date-picker {
        width: 100%;
    }
}

.blue-button {
    cursor: pointer;
    border: none;
    transition: 250ms;

    color: white;
    background-color: #0f62fe !important;

    &:active {
        background-color: #002d9c !important;
    }

    &:hover {
        background-color: #0353e9 !important;
    }
    // .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {

    // }
}

.red-button {
    cursor: pointer;
    border: none;
    transition: 250ms;

    color: white;
    background-color: #da1e28 !important;

    &:active {
        background-color: #750e13 !important;
    }

    &:hover {
        background-color: #b81921 !important;
    }
    // .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {

    // }
}

.yellow-button {
    cursor: pointer;
    border: none;
    transition: 250ms;

    color: white;
    background-color: #dacd1e !important;

    &:active {
        background-color: #75670e !important;
    }

    &:hover {
        background-color: #b8b019 !important;
    }
    // .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {

    // }
}

.black-button {
    cursor: pointer;
    border: none;
    transition: 250ms;

    color: white;
    background-color: #1c1c1c !important;

    &:active {
        background-color: #000000 !important;
    }

    &:hover {
        background-color: #252525 !important;
    }
    // .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {

    // }
}

@mixin cst-colored-buttons($colors) {
    @each $class, $color in $colors {
        .#{$class}:not([disabled]) {
            background-color: nth($color, 1) !important;
            color: nth($color, 2) !important;

            &:hover {
                background-color: darken(nth($color, 1), 5%) !important;
            }

            &:active {
                background-color: darken(nth($color, 1), 10%) !important;
            }
        }
    }
}

@include cst-colored-buttons(
    (
        "cst-btn-orange": (
            #ff5a00,
            white,
        ),
        "cst-btn-green": (
            #00493e,
            white,
        ),
        "cst-btn-yellow": (
            #feee00,
            black,
        ),
        "cst-btn-cyan": (
            #00ccbc,
            black,
        ),
        "cst-btn-pink-red": (
            #dd2a5b,
            white,
        ),

        "cst-btn-purple": (
            #812add,
            white,
        ),
        "cst-btn-bright-green": (
            #008f48,
            white,
        ),
    )
);

.green-button {
    background-color: #00551a !important;

    &:active {
        background-color: #004414 !important;
    }

    &:hover {
        background-color: #004414 !important;
    }
}

.gender-button-male {
    background-color: #2d23b6 !important;

    &:active {
        background-color: darken(#2d23b6, 15%) !important;
    }

    &:hover {
        background-color: lighten(#2d23b6, 15%) !important;
    }
}
.gender-button-female {
    background-color: #550052 !important;

    &:active {
        background-color: darken(#550052, 15%) !important;
    }

    &:hover {
        background-color: lighten(#550052, 15%) !important;
    }
}

.advance-payment-button {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #550052 !important;

        &:active {
            background-color: darken(#550052, 15%) !important;
        }

        &:hover {
            background-color: lighten(#550052, 15%) !important;
        }
    }
}

.cash-button {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #00551a !important;

        &:active {
            background-color: darken(#00551a, 15%) !important;
        }

        &:hover {
            background-color: lighten(#00551a, 15%) !important;
        }
    }
}

.credit-card-button {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #ff2800 !important;

        &:active {
            background-color: #d12100 !important;
        }

        &:hover {
            background-color: #9c1800 !important;
        }
    }
}

.debit-card-button {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #2d23b6 !important;

        &:active {
            background-color: darken(#2d23b6, 15%) !important;
        }

        &:hover {
            background-color: lighten(#2d23b6, 15%) !important;
        }
    }
}

.transparent-button {
    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #ffffff00 !important;

        &:active {
            background-color: #ffffff20 !important;
        }

        &:hover {
            background-color: #ffffff40 !important;
        }
    }
}

.white-combo-box-arrow {
    .bx--list-box__menu-icon > svg {
        fill: white !important;
    }
    .bx--list-box__selection > svg {
        fill: white !important;
    }
    .bx--select__arrow {
        fill: white !important;
    }
}

.modal-that-supports-combo-box {
    .bx--modal-container {
        overflow: visible !important;
    }

    .bx--modal-content {
        overflow: visible !important;
    }
}

@keyframes monitoring-sales-value-anim {
    0% {
        transform: scale(1);
        color: rgba(255, 99, 132, 1);
    }
    25% {
        transform: scale(1.2);
        color: green;
    }
    75% {
        transform: scale(1.2);
        color: green;
    }
    100% {
        transform: scale(1);
        color: rgba(255, 99, 132, 1);
    }
}

.full-width-combo-box-field {
    .bx--list-box__wrapper {
        flex: 1;
    }
}

.white-carbon-check-box {
    .bx--checkbox-label::before {
        border: 1px solid #ececec !important;
        border-radius: 5px !important;
    }
}

.scroll-to-top-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 60px;
    height: 60px;

    color: white;
    // fill: white;

    font-size: 20px;

    border-radius: 50%;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
        0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    // background: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: 250ms;

    transform: scale(1);

    &:hover {
        transform: scale(1.1);
        border-radius: 15px;
        font-size: 30px;
        // color: #4e2e89;
    }

    &:active {
        transform: scale(0.9);
        font-size: 20px;
    }
}

.icon-btn {
    cursor: pointer;
    transition: 250ms;

    &:hover {
        transform: scale(1.2);
    }

    &:active {
        transform: scale(0.9);
    }
}

.icon-btn-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 250ms;
    opacity: 0.65;

    &:hover {
        opacity: 1;
    }

    &:active {
        opacity: 1;
    }
}

@keyframes slide-in-anim {
    0% {
        height: 60px;
        opacity: 0;
    }
    100% {
        height: 120px;
        opacity: 1;
    }
}

.short-width-tabs {
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
        width: 105px;
        // width: auto;
    }
}

// .mobile-tabs {
//     .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
//         // width: 105px;
//         // width: auto;
//         // flex: 1;
//         width: unset;
//     }
// }

.minimal-input {
    .bx--text-input {
        padding: 5px;
    }
}

.no-input-border {
    .bx--text-input {
        // border-bottom: unset !important;
        border-bottom: 1px solid #e0e0e0 !important;
    }
    .bx--date-picker__input {
        // border-bottom: unset !important;
        border-bottom: 1px solid #e0e0e0 !important;
    }
}

.no-input-border-2 {
    .bx--text-input {
        border-bottom: unset !important;
        // border-bottom: 1px solid #e0e0e0 !important;
    }
    .bx--date-picker__input {
        border-bottom: unset !important;
        // border-bottom: 1px solid #e0e0e0 !important;
    }

    .bx--list-box {
        border-bottom: unset !important;
    }
}

.full-width-box-wrapper {
    .bx--list-box__wrapper {
        flex: 1;
    }
}

.centered-progress-bar {
    .bx--inline-loading {
        width: unset !important;
    }
}

.really_centered-progress-bar {
    .bx--inline-loading {
        width: unset !important;
    }

    .bx--inline-loading__animation {
        margin-right: unset !important;
    }
}

.progress-bar-segment-border {
    border-top: white;
    border-top-style: solid;
    border-top-width: 1;
}

.no-striped-green-bg {
    background-color: #4d8542;
}

.striped-green-bg {
    background-image: linear-gradient(
        45deg,
        #4d8542 25%,
        #a7cf9b 25%,
        #a7cf9b 50%,
        #4d8542 50%,
        #4d8542 75%,
        #a7cf9b 75%,
        #a7cf9b 100%
    );
    background-size: 15px 15px;
}

.striped-grey-bg {
    background-image: linear-gradient(
        45deg,
        #808080 25%,
        #cecece 25%,
        #cecece 50%,
        #808080 50%,
        #808080 75%,
        #cecece 75%,
        #cecece 100%
    );
    background-size: 15px 15px;
}

.tooltip-supported-toolbar {
    .bx--table-toolbar {
        overflow: visible !important;
    }
}

.height-change-supported-toolbar {
    .bx--table-toolbar {
        height: unset !important;
    }

    .bx--toolbar-content {
        height: unset !important;
        align-items: flex-end !important;
    }
}

.white-product-field {
    .product-field-class {
        background: white !important;
    }
}

.centered-content-tile {
    div:first-of-type {
        flex-wrap: wrap;
    }
    .bx--tile--selectable {
        padding-right: 1rem;
    }
}

.sales-cart-type-filter-tag {
    transition: 250ms;

    &:hover {
        transform: scale(1.1);
        opacity: 1 !important;
    }

    &:active {
        transform: scale(0.9);
    }
}

.sales-cart-collapsed-panel {
    cursor: pointer;

    background-color: #1c1c1c;
    color: white;

    transition: background-color 250ms;

    &:hover {
        background-color: #252525;
    }
}

.full-width-tabs {
    .bx--tabs--scrollable__nav {
        width: 100% !important;
    }

    .bx--tabs--scrollable__nav-item {
        flex: 1 !important;
    }

    .bx--tabs--scrollable__nav-link {
        flex: 1 !important;
        width: unset !important;
    }
}

.unfocused-tab {
    button {
        outline: unset !important;
        &:focus {
            //outline: 2px solid #990000 !important;
            outline: unset !important;
        }
    }
}

.center-accordion-header {
    .bx--accordion__heading {
        display: flex;
        align-items: center;
    }
}

.blue-tab {
    button {
        border-bottom: 2px solid #0f62fe !important;
        color: #0f62fe !important;
    }
}

.red-tab {
    button {
        border-bottom: 2px solid #990000 !important;
        color: #990000 !important;
    }
}

.yellow-tab {
    button {
        border-bottom: 2px solid #997d00 !important;
        color: #997d00 !important;
    }
}

.green-tab {
    button {
        border-bottom: 2px solid #00551a !important;
        color: #00551a !important;
    }
}

.black-tab {
    button {
        border-bottom: 2px solid black !important;
        color: black !important;
    }
}

.work-list-item {
    transition: 250ms;
    cursor: pointer;

    &:hover {
        background: #00000015;
    }
}

.work-list-item-shortcut-btn {
    transition: 250ms;
    cursor: pointer;

    background: transparent;
    color: white;

    &:hover {
        background: #ffffff20;
        color: white;
    }
}

.work-list-item-shortcut-btn-danger {
    transition: 250ms;
    cursor: pointer;

    background: transparent;
    color: red;

    &:hover {
        background: #99000090;
        color: red;
    }
}

.work-list-item-shortcut-btn-positive {
    transition: 250ms;
    cursor: pointer;

    background: transparent;
    color: green;

    &:hover {
        background: #00551a80;
        color: green;
    }
}

.stripped-down-tab-content {
    .bx--tab-content {
        padding: 0px;

        &:focus {
            outline: unset !important;
        }
    }
}

.stripped-down-tab-content-full-height {
    .bx--tab-content {
        padding: 0px;
        flex: 1;
        min-height: 0px;

        &:focus {
            outline: unset !important;
        }
    }
}

.stripped-down-tab-content-2 {
    .bx--tab-content {
        padding: 1rem;

        &:focus {
            outline: unset !important;
        }
    }
}

.no-outline-modal {
    .bx--modal-content:focus {
        outline: unset !important;
    }
}

.sales-cart-grid-item {
    width: 300px;
    height: 500px;

    border-radius: 15px;

    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: 250ms;

    &:hover {
        transform: scale(1.1);
        border-radius: 15px;
        font-size: 30px;
        // color: #4e2e89;
    }

    &:active {
        transform: scale(0.9);
        font-size: 20px;
    }
}

.tab-allow-long-name {
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
        width: unset !important;
        min-width: 10rem;
    }
}

.pin-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;

    transition: 250ms;

    animation: pin-appear-anim 250ms;

    border-radius: 5px;
    background: transparent;

    &:hover {
        transform: scale(1.1);
        background: #ffffff25;
    }

    &:active {
        transform: scale(0.9);
        background: #ffffff25;
    }
}

.pin-button-pinned {
    background: #ffffff25;
}

@keyframes pin-appear-anim {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.no-padding-accordion {
    .bx--accordion__content {
        padding-right: 1rem !important;
    }

    .bx--toggle-input__label .bx--toggle__switch {
        margin-top: unset !important;
    }
}

.complete-no-padding-accordion {
    .bx--accordion__content {
        // padding-right: 1rem !important;
        padding: 0rem !important;
    }

    .bx--toggle-input__label .bx--toggle__switch {
        margin-top: unset !important;
    }
}

.no-padding-toggle-control {
    .bx--toggle-input__label .bx--toggle__switch {
        margin-top: unset !important;
    }
}

@keyframes actual-opacity-fade-in-anim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opacity-fade-in-anim {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes opacity-fade-in-md-anim {
    0% {
        opacity: 0;
        transform: translateY(-25px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes opacity-fade-in-lg-anim {
    0% {
        opacity: 0;
        transform: translateY(-45px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes comment-fade-in-anim {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.sortableHelper {
    // z-index: 100000 !important;
    z-index: 100000001 !important;
}

.modal-that-supports-scrolling {
    overflow: auto !important;
    // padding-top: 25% !important;
    // padding-bottom: 25% !important;
    .bx--modal-container {
        max-height: unset !important;
    }
}

.high-z-modal {
    .bx--modal {
        z-index: 10009 !important;
    }
}

.no-label {
    label {
        display: none !important;
    }
}

.full-size-close-btn {
    button {
        width: 48px !important;
        height: 48px !important;
    }
}

.dark-minimal-scroll-bar {
    ::-webkit-scrollbar {
        width: 6px;
        height: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #00000000;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #00000040;
        border-radius: 100vh;
        // border: 3px solid #edf2f7;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #00000099;
    }
}

.light-minimal-scroll-bar {
    ::-webkit-scrollbar {
        width: 6px;
        height: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #ffffff00;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 100vh;
        // border: 3px solid #edf2f7;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ffffff99;
    }
}

.dark-accordion {
    .bx--accordion__item:last-child {
        border-bottom: 1px solid #1d1d1d !important;
    }

    .bx--accordion__item {
        border-top: 1px solid #1d1d1d !important;
    }

    .bx--accordion__arrow {
        fill: #efefef !important;
    }

    .bx--accordion__heading {
        color: #efefef !important;
    }

    .bx--accordion__heading:hover::before {
        background-color: #1c1c1c !important;
    }
}

.rounded-button {
    background-color: #0f62fe;
}

.forced-blocked-body {
    overflow: hidden;
    position: fixed;
    overflow: hidden !important;
    height: 100% !important;
    width: 100% !important;
}

.dark-menu-item {
    .bx--list-box__menu-item {
        color: rgba(255, 255, 255, 0.65) !important;
        &:hover {
            background-color: #252525 !important;
        }
    }
}

.align-slider {
    height: 100%;

    .embla {
        padding: 0px;
        height: 100%;
    }
    .embla__viewport {
        overflow: hidden;
        height: 100%;
    }
    .embla__container {
        display: flex;
        flex-direction: row;
        height: 100%;
        margin-left: calc(1rem * -1);
    }
    .embla__slide {
        flex: 0 0 80%;
        min-width: 0;
        padding-left: 1rem;
        position: relative;
    }
    .embla__slide__img {
        display: block;
        height: 19rem;
        width: 100%;
        object-fit: cover;
    }
}

.align-slider-2 {
    height: 100%;

    .embla {
        padding: 0px;
        height: 100%;
    }
    .embla__viewport {
        overflow: hidden;
        height: 100%;
    }
    .embla__container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }
    .embla__slide {
        flex: 0 0 100%;
        min-width: 0;
        position: relative;
    }
}

.mobile-icon-white-tab {
    .bx--tabs--scrollable {
        width: 100% !important;
        color: white !important;
    }

    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item:hover
        .bx--tabs--scrollable__nav-link {
        color: #ffffffdd !important;
    }

    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link,
    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link:active {
        color: white !important;
    }

    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
        color: #ffffff99 !important;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bx--tabs--scrollable__nav {
        width: 100% !important;

        li {
            flex: 1;
        }
    }
}

.mobile-icon-black-tab {
    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bx--tabs--scrollable__nav {
        width: 100% !important;

        li {
            flex: 1;
        }
    }
}

@media screen and (max-width: 767px) {
    .fc .fc-toolbar-title {
        font-size: 14px !important;
    }

    .fc .fc-button {
        font-size: 12px !important;
    }

    .fc-toolbar-title {
        text-align: center;
        &::after {
            content: "tap and hold to create/edit";
            display: block;
            font-size: 10px;
            opacity: 0.65;
            text-align: center;
            /* Additional styles for the subtitle */
        }
    }

    // .fc-toolbar.fc-header-toolbar {
    //     flex-direction: column;
    //     order: 2;
    // }
    // .fc-toolbar-chunk {
    //     display: table-row;
    //     text-align: center;
    //     padding: 5px 0;
    // }
}

.any-height-combo {
    .bx--list-box__menu-item__option {
        height: unset !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .bx--list-box__menu-item {
        height: unset !important;
    }
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

.two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-round-or-border-content-switcher {
    .bx--content-switcher-btn {
        border-radius: 0px !important;
        border: unset !important;
    }
}
